import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ChatMessagesService} from './chat-messages.service';
import {ChatVehicleListEntry} from '../chat-vehicle-list.entry';
import {MessageEntry} from '../message.entry';
import {NotificationService} from '../../../notification/notification.service';
import {GlobalService} from '../../../global/global.service';
import {Module, Profile} from 'app/core/model/profile';
import {Pageable} from '../../../page/pageable';
import {Subject, Subscription, timer} from 'rxjs';
import {ChatService} from '../chat.service';
import {GlobalConstants} from '../../../global/global.constants';
import {ChatUtils} from '../chat.utils';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ChatMarkReadEntry} from './chat-mark-read.entry';
import {Moment} from 'moment';
import {ChatWsStateService} from '../../../ws/chat-ws-state.service';
import {EmployeeReadEntry} from '../model/employee-read.entry';
import {TranslateService} from '@ngx-translate/core';
import {PicturePdfRequest} from '../scan-list/picture-pdf.request';
import {takeUntil} from 'rxjs/operators';
import {SubscriptionUtils} from '../../../commons/subscription.utils';
import {ChatConfigsService} from '../../../../pages/client/settings/global-config/chat-configs/chat-configs.service';
import {KeyboardCommands} from '../../../../pages/client/settings/global-config/chat-configs/model/keyboard-commands';
import {AuthStorage} from '../../../auth/auth-storage';
import {MessageFileType} from '../model/message-file.type';
import {DeviceService} from '../../../commons/device.service';
import {FileUtils, PDF} from '../../../commons/file.utils';
import {Router} from '@angular/router';

export const FLEETHAND_EMPLOYEE_EMAIL_DOMAIN = '.*\\bprogrammatus|fleethand\\b.*'

const MAX_MESSAGE_LENGTH = 10000;

@Component({
  selector: 'app-chat-messages',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessagesComponent implements OnInit, OnDestroy {

  @ViewChild('upload', {static: true}) upload: ElementRef;

  @Input('chatInfo')
  set chatInfo(info: ChatVehicleListEntry) {
    if (info.plateNumber !== '' && info.id !== 0) {
      this.saveOldMessage();
      if (this.fileUri == null) {
        this.getChatFilesUri();
      }
      this._chatInfo = info;
      this.messages = [];
      if (this.filterOn) {
        this.filterMessages();
      } else {
        this.initializeChat();
      }
      this.setPreviousMessageText(info.id);
    }
  }

  @Input()
  public fileUri: string;

  private _chatInfo: ChatVehicleListEntry;

  public newChatText = '';
  public pageable: Pageable = Pageable.default();
  public messages: MessageEntry[] = [];
  public lastSeenMessage: number;
  public lastReadMessageId: number;
  public loggedUser: Profile = <Profile>{};

  public closeMessagesSubscription: Subscription;
  private newMessagesSubscription: Subscription;
  private subSubject = new Subject<boolean>();

  public inSwitzerland = false;

  public throttle = 500;
  public scrollUpDistance = 3;
  public scrollDownDistance = 3;

  public MessageFileType = MessageFileType;

  private lastUpdateId: number = null;
  public file: any;

  public modalVisible;
  public showingFile: number;

  public messagesTemplatesVisible = false;
  public messagesTemplates: string[];

  public filteredMessages: MessageEntry[] = [];
  public filter: UntypedFormGroup;
  public filterOn = false;
  public filteredMessage: number;
  public loadLock = false;

  public filterMonthlyIntervals: string[] = [];
  private readonly EARLIEST_CHAT_DATE = '2017-11-01';
  private filterMessageLoadCount = 10;

  public hideMessagesWithFiles = false;
  public messagesToShow: MessageEntry[] = [];

  public enterSelection = AuthStorage.getChatEnterConfigs();
  public shiftEnterSelection = AuthStorage.getChatShiftEnterConfigs();

  constructor(private chatMessagesService: ChatMessagesService,
              private notification: NotificationService,
              private globalService: GlobalService,
              private chatService: ChatService,
              private router: Router,
              private cd: ChangeDetectorRef,
              private chatWsStateService: ChatWsStateService,
              private chatConfigsService: ChatConfigsService,
              private fb: UntypedFormBuilder,
              private translate: TranslateService,
              private deviceService: DeviceService) {

    this.filter = this.fb.group({
      'textSearch': [''],
      'dateFrom': [''],
      'dateTill': ['']
    });

    this.closeMessagesSubscription = this.chatService.closeMessages$
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          if (this.newMessagesSubscription != null) {
            this.newMessagesSubscription.unsubscribe();
          }
          this.file = null;
          this.lastUpdateId = null;
        },
        (err) => this.notification.loadingError()
      );
  }

  public ngOnInit(): void {
    this.getChatFilesUri();
    this.getLoggedProfile();
    if (window.innerWidth > GlobalConstants.MOBILE_WIDTH_BREAKPOINT) {
      ChatUtils.responsiveTextarea('text-m-input', 'message-m-list', 'text-buttons', 'upload-m-file', 'chat-m-templates');
    }
  }

  private setPreviousMessageText(vehicleId: number): void {
    let messages = AuthStorage.getVehiclesMessages();
    let oldMessage = messages.get(vehicleId);
    if (oldMessage != null) {
      this.newChatText = oldMessage;
    }
  }

  private saveOldMessage(): void {
    if (this._chatInfo != null && this._chatInfo.id != null) {
      let messages = AuthStorage.getVehiclesMessages();
      if (this.newChatText.trim() != '') {
        messages.set(this._chatInfo.id, this.newChatText);
      } else {
        messages.delete(this._chatInfo.id);
      }
      AuthStorage.persistVehiclesMessages(messages);
      this.newChatText = '';
    }
  }

  public fileChange(upload) {
    if (upload.files.length && upload.files[0].size < GlobalConstants.UPLOAD_FILE_LIMIT) {
      this.file = upload.files[0];
      this.resize();
    } else {
      this.removeFile();
      this.notification.fileLimitExceeded();
    }
  }

  public removeFile(): void {
    this.file = null;
    this.upload.nativeElement.value = '';
    this.resize();
  }

  public onKeyEnter(event: any): void {
    if (this.enterSelection === KeyboardCommands.ENTER) {
      event.preventDefault()
      this.addChatItem();
    }
  }

  public onKeyShiftEnter(event: any): void {
    if (this.shiftEnterSelection === KeyboardCommands.ENTER) {
      event.preventDefault()
      this.addChatItem();
    }
  }

  public addChatItem(): void {
    if (this.newChatText.trim() !== '') {
      this.saveMessageText(this.addNewMessage(this.newChatText));
    }
    if (this.file != null) {
      this.saveMessageFile(this.addNewMessage(this.file.name));
    }
    if (window.innerWidth > GlobalConstants.MOBILE_WIDTH_BREAKPOINT) {
      this.resize();
    }
    this.newChatText = '';
  }

  public showMessagesTemplates(): void {
    this.messagesTemplatesVisible = !this.messagesTemplatesVisible;
    if (this.messagesTemplatesVisible) {
      this.chatService.fetchTemplateMessages(false)
        .pipe(takeUntil(this.subSubject))
        .subscribe({
          next: (messages) => {
            this.messagesTemplates = messages.sort();
            this.cd.markForCheck();
            this.resize();
          },
          error: () => this.notification.loadingError()
        });
    }
    this.resize();
  }

  public setMessage(message: string): void {
    this.newChatText = message;
  }

  public navigateToSettings(): void {
    this.router.navigate(['/configs/data'], {state: {tab: 'chat'}});
  }

  private addNewMessage(messageText: string): MessageEntry {
    let sentOn = moment(new Date()).format('YYYY-MM-DD HH:mm');
    let sender = this.loggedUser.fullName;
    return new MessageEntry(
      null,
      'MANAGER',
      sender,
      null,
      null,
      sentOn,
      this._chatInfo.id,
      messageText,
      '',
      null,
      null,
      false,
      this.senderChanged(sender) || ChatUtils.silentInterval(sentOn, this.messages),
      null,
      null);
  }

  private senderChanged(sender: string): boolean {
    let length = this.messages.length - 1;
    if (length > 0) {
      let lastMessage = this.messages[length];
      return sender !== lastMessage.sender;
    }
    return true;
  }

  public showFile(message: MessageEntry, event?: any): void {
    if (event) {
      event.stopPropagation();
    }

    this.showingFile = message.id;
    setTimeout(() => {
      let element = document.getElementById('image-' + message.id);
      let collapsed = element.classList.toggle('collapse');
      if (message.fileType === MessageFileType.VOICE_MESSAGE) {
        this.handleAudioFile(collapsed, element);
      }
    }, 200);
  }

  private handleAudioFile(collapsed: boolean, element: HTMLElement): void {
    let audios = element.getElementsByTagName('audio');
    if (audios != null && audios.length === 1) {
      let audioElement = audios.item(0);
      if (collapsed) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
    }
  }

  public markAsUnread(message: MessageEntry): void {
    let flag = document.getElementById('readBy-' + message.id);
    while (flag != null) {
      let parentElement = flag.parentElement;
      parentElement.removeChild(flag);
      flag = document.getElementById('readBy-' + message.id);
    }
    let boxMessages = this.getMessagesOfBox(message);
    this.chatMessagesService.markAsUnread(boxMessages)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        () => {},
        (error) => this.notification.loadingError()
      );
  }

  private getMessagesOfBox(message: MessageEntry): ChatMarkReadEntry[] {
    let messageIds = [message.id];
    const sender = message.senderType;
    let index = this.messages.indexOf(message);
    let next = this.messages[++index];
    while (next != null && next.senderType === sender && !next.drawNewBox) {
      messageIds.push(next.id);
      next.employeeRead = null;
      next = this.messages[++index];
    }
    return this.messages
      .filter(msg => messageIds.some(id => id === msg.id))
      .map(msg => ({id: msg.id, hasFile: msg.fileId != null}));
  }

  private initializeChat(): void {
    if (!AuthStorage.isLoggedPartnerEmployee() || AuthStorage.getModules().includes(Module.MANAGEMENT)) {
      this.messages = [];
      this.getInitMessages();
      this.chatMessagesService.getLastSeenMessage(this._chatInfo.id)
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          (data) => {
            this.lastSeenMessage = data;
          }
        );
      this.newMessagesSubscription = this.chatWsStateService.countByVehicleStateless$
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          countByVehicle => {
            if (this.lastUpdateId != null && countByVehicle[this._chatInfo.id]) {
              this.updateMessages();
            }
          }
        );

      this.chatService.isInSwitzerland(this._chatInfo.id)
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          (data) => this.inSwitzerland = data,
          (err) => this.notification.loadingError()
        );
    }
  }

  public getInitMessages(): void {
    this.loadLock = true;
    this.chatMessagesService.fetchInitMessages(this._chatInfo.id)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (response) => {
          response.messages.forEach(msg => {
            if (this.messages.filter(m => m.id === msg.id).length === 0) {
              this.messages.unshift(msg);
            }
          });
          this.lastReadMessageId = response.lastSeenMessageId;

          this.setLastUpdatedId();
          ChatUtils.scrollDown('message-m-list');
          this.applyFilesFilter(this.hideMessagesWithFiles);
          this.loadLock = false;
        },
        (err) => {
          this.loadLock = false;
          this.notification.loadingError()
        }
      );
  }

  private setLastUpdatedId() {
    this.lastUpdateId = this.messages[this.messages.length - 1] != null
      ? this.messages[this.messages.length - 1].id
      : null;
  }

  public getMessages(): void {
    if (!this.loadLock) {
      let lastMessage = this.messages[0] != null ? this.messages[0].id : -1;
      this.chatMessagesService.fetchMessages(this._chatInfo.id, lastMessage)
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          (data) => {
            data.forEach(d => {
              if (this.messages.filter(m => m.id === d.id).length === 0) {
                this.messages.unshift(d);
              }
            });
            this.setLastUpdatedId();
            this.applyFilesFilter(this.hideMessagesWithFiles)
          },
          (err) => this.notification.loadingError()
        );
    }
  }

  private updateMessages() {
    this.chatMessagesService.updateChat(
      this._chatInfo.id,
      this.lastUpdateId)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this._chatInfo.tabOnline = data.tabOnline;
          this.lastSeenMessage = data.lastSeenMessage;
          data.newMessages.sort((m1, m2) => m1.id > m2.id ? 1 : -1).forEach(d => {
            if (!this.messages.includes(d)) {
              this.messages.push(d);
            }
            this.lastUpdateId = d.id;
          });
          this.cd.markForCheck();
          if (data.newMessages.length > 0) {
            ChatUtils.scrollDown('message-m-list');
          }
        }
      );
  }

  private getLoggedProfile() {
    this.globalService.getLoggedProfile()
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        data => this.loggedUser = data,
        err => this.notification.loadingError()
      );
  }

  public isPicture(message: MessageEntry): boolean {
    let file = message.file;
    if (file.length > 3) {
      let extension = file.substring(file.lastIndexOf('.'), file.length);
      return ChatUtils.PICTURE_EXTENSION.includes(extension);
    } else {
      return false;
    }
  }

  public isTabOffline(): boolean {
    return this._chatInfo != null && !this._chatInfo.tabOnline;
  }

  public showDate(message: MessageEntry): boolean {
    let nextMessageIdx = this.messages.indexOf(message);
    let next = this.messages[++nextMessageIdx];
    return next == null || next.drawNewBox;
  }

  private resize() {
    setTimeout(() => {
      ChatUtils.resize('message-m-list', 'text-m-input', 'text-buttons', 'upload-m-file', 'chat-m-templates');
    }, 0);
  }

  private saveMessageFile(newMessage: MessageEntry): void {
    this.chatMessagesService.saveMultipartMessage(
      newMessage.vehicleId,
      newMessage.messageText,
      this.file)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this.pushMessage(data);
          this.removeFile();
        },
        (err) => {
          if (err.status === 415) {
            this.notification.error('global.error.unsupported-media-type');
          } else {
            this.notification.loadingError();
          }
        }
      );
  }

  private saveMessageText(newMessage: MessageEntry): void {
    if (newMessage.messageText.length <= MAX_MESSAGE_LENGTH) {
      this.chatMessagesService.saveMessage(
        newMessage.vehicleId,
        newMessage.messageText)
        .pipe(takeUntil(this.subSubject))
        .subscribe({
          next: (data) => {
            this.lastUpdateId = data.id;
            newMessage.id = data.id;
            newMessage.sentOn = data.sentOn;
            this.pushMessage(newMessage);
          },
          error: () => this.notification.loadingError()
        });
    } else {
      this.notification.error(this.translate.instant('chat.messages.textMaxSymbols') + MAX_MESSAGE_LENGTH)
    }
  }

  private pushMessage(newMessage: MessageEntry) {
    this.messages.push(newMessage);
    this.cd.markForCheck();
    ChatUtils.scrollDown('message-m-list');
  }

  public flushFiles(): void {
    this.inSwitzerland = false;
    this.chatService.flushFiles(this._chatInfo.id)
      .pipe(takeUntil(this.subSubject))
      .subscribe();
  }

  public downloadFile(vehicleId: number, fileId: number) {
    let plateNumber = this._chatInfo.plateNumber.replace(/[^a-zA-Z0-9]/g, '_');
    let currentTime = moment().format('YYYY-MM-DD_HH:mm');
    this.chatService.generateToken(vehicleId, fileId)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this.chatService.downloadFile(data.token, plateNumber)
            .pipe(takeUntil(this.subSubject))
            .subscribe({
              next: data => FileUtils.saveFile(data, `${currentTime}-${plateNumber}`)
            })
        },
        (err) => this.notification.loadingError()
      );
  }

  public downloadPictureAsPdf(VehicleId: number, fileName: string, fileId: number) {
    let plateNumber = this._chatInfo.plateNumber;
    let picture: PicturePdfRequest[] = [];
    let currentTime = moment().format('YYYY-MM-DD_HH:mm');
    picture[0] = new PicturePdfRequest(fileId, fileName);
    this.chatService.generateToken(VehicleId, fileId)
      .pipe(takeUntil(this.subSubject))
      .subscribe((data) => this.chatService.getPicturesAsPdf(data.token, picture, plateNumber)
          .pipe(takeUntil(this.subSubject))
          .subscribe(
            (blob) => FileUtils.saveFile(blob, `${currentTime}-${plateNumber.replace(/[^a-zA-Z0-9]/g, '_')}`, PDF),
            (err) => this.notification.loadingError()
          ),
        (error) => this.notification.loadingError());

  }

  public showModal(id: number): void {
    this.modalVisible = id;
  }

  public closeModal(): void {
    this.modalVisible = null;
  }

  public filterMessages() {
    let textSearch = this.filter.controls.textSearch.value;
    let dateFrom = this.filter.controls.dateFrom.value;
    let dateTill = this.filter.controls.dateTill.value;

    if (textSearch != null && textSearch !== '') {
      this.filterMonthlyIntervals = this.getMonthlyIntervals(moment(this.EARLIEST_CHAT_DATE), moment());
      this.performFiltering(textSearch);
    } else if (dateFrom != null && dateFrom !== '') {
      const maxDate = dateTill != null && dateTill !== ''
        ? moment(dateTill)
        : moment();
      this.filterMonthlyIntervals = dateFrom === dateTill
        ? [dateFrom, dateFrom]
        : this.getMonthlyRangesIntervals(moment(dateFrom), moment(maxDate));
      this.performFiltering(null);
    } else {
      this.filterOn = false;
      this.messages = [];
      this.getInitMessages();
    }
  }

  private performFiltering(textSearch: string) {
    this.filterOn = true;
    this.filteredMessages = [];
    this.filterMessageLoadCount = 10;
    this.fetchFilterMessagesInterval(textSearch);
  }

  public loadMoreFilterMessages() {
    let textSearch = this.filter.value.textSearch !== ''
      ? this.filter.value.textSearch
      : null;
    if (this.filterMonthlyIntervals.length >= 2) {
      this.filterMessageLoadCount += 10;
      this.fetchFilterMessagesInterval(textSearch);
    }
  }

  private fetchFilterMessagesInterval(textSearch: string) {
    const messageFilter = this.getIntervalMessageFilter(textSearch);
    this.chatMessagesService.filterMessages(this._chatInfo.id, messageFilter)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (messages) => {
          const orderedMessages = [];
          messages.forEach(d => {
            orderedMessages.unshift(d);
          });
          this.filteredMessages.push(...orderedMessages);
          this.cd.markForCheck();
          this.shiftIntervalAndFetchAgain(textSearch);
        },
        (err) => this.notification.loadingError()
      );
  }

  private shiftIntervalAndFetchAgain(textSearch: string) {
    this.filterMonthlyIntervals.shift();
    if (!(this.filterMonthlyIntervals.length < 2 || this.filteredMessages.length > this.filterMessageLoadCount)) {
      this.fetchFilterMessagesInterval(textSearch);
    } else {
      this.filterMessageLoadCount = this.filteredMessages.length;
      this.applyFilesFilter(this.hideMessagesWithFiles);
    }
  }

  private getIntervalMessageFilter(textSearch: string) {
    return {
      textSearch: textSearch,
      dateFrom: this.filterMonthlyIntervals[0],
      dateTill: this.filterMonthlyIntervals[1]
    }
  }

  private getMonthlyIntervals(minDate: Moment, maxDate: Moment): string[] {
    let monthlyIntervals = [];
    let currentDate = minDate;
    while (currentDate < maxDate) {
      monthlyIntervals.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'month');
    }
    monthlyIntervals.push(moment(maxDate).format('YYYY-MM-DD'))
    return monthlyIntervals;
  }

  private getMonthlyRangesIntervals(minDate: Moment, maxDate: Moment): string[] {
    const monthlyIntervals = this.getMonthlyIntervals(minDate, maxDate);
    const lastDate = moment(maxDate).format('YYYY-MM-DD');
    if (!monthlyIntervals.includes(lastDate)) {
      monthlyIntervals.push(lastDate)
    }
    return monthlyIntervals;
  }

  public closeFilter() {
    this.filterOn = false;
    this.clearFilter();
    this.messages = [];
    this.getInitMessages();
  }

  public findAndScrollTo(message: MessageEntry): void {
    this.chatMessagesService.loadFiltered(this._chatInfo.id, message.sentOn)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this.loadLock = true;
          this.filterOn = false;
          this.messages = [];
          data.forEach(d => {
            this.messages.unshift(d);
          });
          this.cd.markForCheck();
          this.filteredMessage = message.id;
          this.applyFilesFilter(this.hideMessagesWithFiles);
          timer(500)
            .pipe(takeUntil(this.subSubject))
            .subscribe(() => this.scrollTo(message));
        },
        (err) => this.notification.loadingError()
      );
  }

  private scrollTo(message: MessageEntry) {
    const messageElem = document.getElementById(`message-${message.id}`);
    if (messageElem) {
      messageElem.scrollIntoView(false);
    }
    const messageListElem = document.getElementById('message-m-list');
    const scrollTop = messageListElem.scrollTop !== 0
      ? messageListElem.scrollTop + 400
      : messageListElem.scrollTop;
    jQuery(messageListElem).animate({scrollTop: scrollTop}, 'slow');
    timer(500)
      .pipe(takeUntil(this.subSubject))
      .subscribe(() => this.loadLock = false);
  }

  public addMessages(): void {
    if (!this.loadLock && this.messages.length > 0) {
      let lastMessageId = this.messages[this.messages.length - 1].id;
      this.chatMessagesService.addMessages(this._chatInfo.id, lastMessageId)
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          (data) => {
            data.forEach(d => {
              if (this.messages.filter(m => m.id === d.id).length === 0) {
                this.messages.push(d);
              }
            });
            this.applyFilesFilter(this.hideMessagesWithFiles);
            this.cd.markForCheck();
          },
          (err) => this.notification.loadingError()
        );
    }
  }

  public expandInput() {
    jQuery('.chatDateFrom').toggleClass('expanded');
    jQuery('.chatDateTill').toggleClass('expanded');
    jQuery('.chatTextSearch').toggleClass('hidden');
    this.clearFilter();
  }

  public applyFilesFilter(checked: boolean) {
    this.hideMessagesWithFiles = checked;
    if (this.hideMessagesWithFiles) {
      this.messagesToShow = this.filterOn
        ? this.filteredMessages.filter(message => message.fileId == null)
        : this.messages.filter(message => message.fileId == null);
    } else {
      this.messagesToShow = this.filterOn ? this.filteredMessages : this.messages
    }
    this.cd.markForCheck();
  }

  private clearFilter() {
    this.filter.patchValue({
      textSearch: null,
      dateFrom: null,
      dateTill: null
    });
  }

  public didEmployeeSaw(message: MessageEntry): boolean {
    return message.employeeRead != null && message.employeeRead.some(it => it.accountId === this.loggedUser.id);
  }

  public getEmployeeName(message: MessageEntry): string {
    const employee = message.employeeRead.filter(it => it.accountId === this.loggedUser.id)[0];
    return employee != null ? employee.accountName : '';
  }

  public getSeenOn(message: MessageEntry): string {
    const employee = message.employeeRead.filter(it => it.accountId === this.loggedUser.id)[0];
    return employee != null ? employee.openedOn : '';
  }

  public getReadTooltip(employeeRead: EmployeeReadEntry[]): string {
    const otherEmployees = employeeRead
      .filter(it => it.accountId !== this.loggedUser.id && it.email != null && !it.email.match(FLEETHAND_EMPLOYEE_EMAIL_DOMAIN));
    let msg = '';
    for (let i = 0; i < otherEmployees.length; i++) {
      if (i <= 4) {
        msg += otherEmployees[i].accountName + '\n';
      } else {
        msg += this.translate.instant('chat.messages.readByOthers') + (otherEmployees.length - i);
        break;
      }
    }
    return msg;
  }

  public onPaste(event: any): void {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        event.preventDefault();
        let blob = item.getAsFile();
        if (blob !== null && blob.size < GlobalConstants.UPLOAD_FILE_LIMIT) {
          this.file = blob;
          this.resize();
        } else {
          this.removeFile();
          this.notification.fileLimitExceeded();
        }
      }
      if (item.type.indexOf('text') === 0) {
        let textarea = document.getElementById('text-m-input');
        textarea.style.height = '399px';
      }
    }
  }

  private getChatFilesUri() {
    this.chatService.getChatFilesUri() // TODO: find out if it's still needed or if previous fix was enough
      .pipe(takeUntil(this.subSubject))
      .subscribe(res => this.fileUri = res);
  }

  public ngOnDestroy() {
    SubscriptionUtils.safeUnsubscribe(this.newMessagesSubscription);
    SubscriptionUtils.safeUnsubscribe(this.closeMessagesSubscription);
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }
}

