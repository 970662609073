import {Injectable, OnDestroy} from '@angular/core';
import {Client as StompClient, over as overStomp} from 'stompjs';
import * as SockJS from 'sockjs-client';
import {GlobalService} from '../../global/global.service';
import {BehaviorSubject, interval, Subject, Subscription, timer} from 'rxjs';
import {UserWsInfo} from '../model/user-ws-info';
import {AuthStorage} from '../../auth/auth-storage';
import {LatestVehicleEntry} from '../../component/latest-vehicles-info/model/latest-vehicle-entry';
import {LatestVehiclesCacheEntry} from './model/latest-vehicles-cache-entry';
import * as Pako from 'pako'
import {VehiclesService} from '../../../pages/client/vehicles/vehicles.service';
import {delay, retryWhen} from 'rxjs/operators';

const LATEST_VEHICLES_TOPIC = `/users/assets-state/latest-vehicles`;

const RECONNECT_TIMEOUT = 20 * 1000;
const DROPPED_CONNECTION_INTERVAL = RECONNECT_TIMEOUT * 2;
const STALE_CACHE_REFRESH_IN_SECONDS = 20 * 1000;
const HEARTBEAT_EVERY_MINUTE = 1000 * 60;
const SUSPICIOUSLY_OLD_CACHE_TIME = HEARTBEAT_EVERY_MINUTE * 2;

const fallbackTransports = [
  // 'websocket',
  'xhr-streaming',
  'xdr-streaming',
  'eventsource',
  'iframe-eventsource',
  'htmlfile',
  'iframe-htmlfile',
  'xhr-polling',
  'xdr-polling',
  'iframe-xhr-polling',
  'jsonp-polling'
];

@Injectable({
  providedIn: 'root'
})
export class AssetsStateWebSocketService implements OnDestroy {

  // ---------------------------------
  public ASWSenabled = true; // <---------------------------- note enable assets state latest vehicles / tails
  // ---------------------------------

  private collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

  private deactivateSessionSource = new Subject<void>();
  public deactivateSession$ = this.deactivateSessionSource.asObservable();

  private activateSessionSource = new Subject<void>();
  public activateSession$ = this.activateSessionSource.asObservable();

  private latestVehiclesSource = new BehaviorSubject<LatestVehicleEntry[]>([]);
  public latestVehicles$ = this.latestVehiclesSource.asObservable();

  private stompClient: StompClient;
  private deactivateSessionSubs: Subscription;
  private activateSessionSubs: Subscription;

  private showTrailers: Boolean = false;

  private timeOfCache;
  private isSessionActive = false;
  private droppedConnectionChecker: Subscription;
  private reconnectSub: Subscription;

  private notVisibleVehicles: number[] = [];

  constructor(private globalService: GlobalService,
              private vehiclesService: VehiclesService) {

    this.resetLocalCache();

    this.droppedConnectionChecker = interval(DROPPED_CONNECTION_INTERVAL).subscribe(
      () => {
        if (this.isSessionActive) {
          this.checkForDroppedConnection();
        }
      }
    )
  }

  public getUserAndConnect() {
    if (this.ASWSenabled) {
      this.vehiclesService.getNotVisibleVehicles().subscribe(
        notVisibleVehicles => this.notVisibleVehicles = notVisibleVehicles
      );
      this.globalService.userAssetsStateWsInfo().pipe(retryWhen(delay(RECONNECT_TIMEOUT))).subscribe(
        userInfo => this.connect(userInfo),
      );
    }
  }

  private connect(userInfo: UserWsInfo): void {
    this.clearStompClient();

    const ws = new SockJS(userInfo.url, null, {timeout: RECONNECT_TIMEOUT});
    this.stompClient = overStomp(ws);
    this.stompClient.heartbeat.outgoing = HEARTBEAT_EVERY_MINUTE;
    this.stompClient.heartbeat.incoming = HEARTBEAT_EVERY_MINUTE;

    this.disableLogs();

    AuthStorage.persistClientId(userInfo.clientId);

    const headers = {
      employeeId: userInfo.employeeId,
      clientId: userInfo.clientId,
      token: userInfo.token,
      accountId: userInfo.accountId,
      partner: userInfo.partner
    };

    const self = this;
    this.stompClient.connect(headers, function() {
      self.initSessionsSubs();
      self.initialSessionActivation();
      self.stompClient.subscribe(LATEST_VEHICLES_TOPIC, (event) => {
        try {
          self.decompressAndHandle(event);
        } catch (e) {
          self.handleLatestVehicles(JSON.parse(event.body));
        }
      });
    }, this.errorCallBack);
  }

  private decompressAndHandle(event) {
    const strData = atob(event.body);
    const charData = strData.split('').map((it) => it.charCodeAt(0));
    const binData = new Uint8Array(charData);
    const result = Pako.inflate(binData, { to: 'string' });
    this.handleLatestVehicles(JSON.parse(result));
  }

  public initialSessionActivation() {
    this.stompClient.send('/app/initial-session-activation', {}, JSON.stringify(this.getClientSelection()));
    this.isSessionActive = true;
  }

  private handleLatestVehicles = (vehiclesCache: LatestVehiclesCacheEntry) => {
    if (this.isMessageValid(vehiclesCache)) {
      this.handleValidMessage(vehiclesCache);
    }
  };

  private handleValidMessage(vehiclesCache: LatestVehiclesCacheEntry) {
    this.timeOfCache = moment.utc(vehiclesCache.timeOfCache);

    const visibleForUserVehicles = this.filterUserNotVisibleVehicles(vehiclesCache.latestVehicles);
    const sortedLatestVehicles = visibleForUserVehicles
      .map(entry => new LatestVehicleEntry(entry))
      .sort((v1, v2) => this.collator.compare(v1.plateNumber, v2.plateNumber));
    this.latestVehiclesSource.next(sortedLatestVehicles);
  }

  private filterUserNotVisibleVehicles(latestVehicles: LatestVehicleEntry[]): LatestVehicleEntry[] {
    return latestVehicles.filter(it => !this.notVisibleVehicles.includes(it.id));
  }

  private isMessageValid(vehiclesCache: LatestVehiclesCacheEntry): boolean {
    const length = vehiclesCache.latestVehicles.length;
    return length !== 0;
  }

  public initSessionsSubs() {
    this.unsub(this.activateSessionSubs)
    this.activateSessionSubs = this.activateSession$.subscribe(
      () => this.sendActivateSession()
    );
    this.unsub(this.deactivateSessionSubs)
    this.deactivateSessionSubs = this.deactivateSession$.subscribe(
      () => this.sendDeactivateSession()
    );
  }

  public deactivateSession() {
    this.deactivateSessionSource.next();
  }

  public activateSession() {
    this.activateSessionSource.next();
  }

  public sendDeactivateSession() {
    if (this.stompClient) {
      this.stompClient.send('/app/deactivate-session', {});
      this.isSessionActive = false;
    }
  }

  public sendActivateSession() {
    if (this.stompClient) {
      this.stompClient.send('/app/activate-session', {}, JSON.stringify(this.getClientSelection()));
      this.isSessionActive = true;
      this.refreshIfCacheStale();
    }
  }

  private refreshIfCacheStale() {
    const isCacheStale = this.isCacheExpired(STALE_CACHE_REFRESH_IN_SECONDS);
    if (isCacheStale) {
      this.stompClient.send('/app/refresh', {});
    }
  }

  private isCacheExpired(timeForExpiration: number) {
    const timeAfterLastCache = moment() - this.timeOfCache;
    return timeAfterLastCache > timeForExpiration;
  }

  public toggleShowTrailers(showTrailers: Boolean): void {
    this.showTrailers = showTrailers;
    this.sendUpdateClientSelection();
  }

  public sendUpdateClientSelection() {
    if (this.stompClient) {
      this.stompClient.send('/app/update/selection', {}, JSON.stringify(this.getClientSelection()));
    }
  }

  private getClientSelection() {
    return {
      selectedGroups: this.getUserSelectedGroups(),
      showTrailers: this.showTrailers
    };
  }

  private getUserSelectedGroups(): number[] {
    return AuthStorage.getUserVehicleGroups().length > 0
      ? [...AuthStorage.getUserVehicleGroups().map(it => it.id)]
      : AuthStorage.isLoggedPartnerEmployee()
        ? []
        : [AuthStorage.getUserGroup().id];
  }

  public sendUpdateSubedGroups() {
    if (this.stompClient) {
      this.stompClient.send('/app/update/group-subs', {});
    }
  }

  public errorCallBack = () => {
    this.clearStompClient();
    this.reconnect();
  }

  private reconnect() {
    this.unsub(this.reconnectSub);
    this.reconnectSub = timer(RECONNECT_TIMEOUT).subscribe(() => this.getUserAndConnect());
  }

  public disconnect(): void {
    this.clearStompClient();
    this.resetLocalCache();
    this.unsubAll();
  }

  private clearStompClient() {
    if (this.stompClient) {
      this.stompClient.disconnect();
      this.stompClient = null;
    }
  }

  private disableLogs() {
    this.stompClient.debug = () => {};
  }

  public ngOnDestroy(): void {
    this.unsubAll();
    this.disconnect();
  }

  private unsubAll() {
    this.unsub(this.deactivateSessionSubs);
    this.unsub(this.activateSessionSubs);
    this.unsub(this.droppedConnectionChecker);
  }

  private unsub(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  public resetLocalCache() {
    this.timeOfCache = moment().subtract(1, 'minutes');
    this.latestVehiclesSource.next([]);
    this.showTrailers = false;
  }

  private checkForDroppedConnection() {
    const cacheIsOld = this.isCacheExpired(SUSPICIOUSLY_OLD_CACHE_TIME);
    if (cacheIsOld) {
      this.handleReconnection();
    }
  }

  public handleReconnection() {
    this.clearStompClient();
    this.reconnect();
  }

}
