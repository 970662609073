<div class="row">
  <div class="col-12">
    <div widget class="card ">
      <div class="card-header text-center">`
        <span>{{'alarms.list.title' | translate}}</span>
      </div>
      <div class="card-body widget-body">


        <div class="form-group" [ngClass]="imeiIsValid === '' ? '' : imeiIsValid ? 'has-success' : 'has-danger'">
          <label for="imei" class="form-control-label"> {{imeiIsValid === '' ? '' : imeiIsValid ? 'IMEI is valid: ' + imeiIsValid :
            'Incorrect IMEI or IMEI does not exists'}}</label>
          <input type="text" placeholder="IMEI" [(ngModel)]="imei"
                 id="imei" name="imei" class="form-control"
                 [ngClass]="imeiIsValid ? 'form-control-success' : 'form-control-danger'">
          <div class="input-group-append">
            <button type="button" class="item__action--primary" (click)="checkIMEI()">Check</button>
          </div>
        </div>

        <div class="form-group">
          <label for="exampleSelect3">GPS type</label>
          <select id="exampleSelect3" class="form-control" (change)="changeGPS($event)">
            <option value="truck" selected>Truck</option>
            <option *ngFor="let type of gpsTypes" [value]="type.value">{{type.display}}</option>
          </select>
        </div>

        <!--<div *ngIf="selectedType === 'truck'" class="form-group">-->
          <!--<label for="truckModel">Truck type</label>-->
          <!--<select id="truckModel" class="form-control" (change)="changeGPS($event)">-->
            <!--<option *ngFor="let truck of truckTypes" [value]="truck.value">{{truck.display}}</option>-->
            <!--<option *ngFor="let truck of truckTypes" [value]="truck.value">{{truck.display}}</option>-->
          <!--</select>-->
        <!--</div>-->

        <div *ngIf="imeiIsValid && hasCan()" role="alert" class="alert alert-warning"><strong>Attention!</strong> Switch
          ignition ON before submit! If ignition is already ON, switch it OFF and ON again!
        </div>
        <a href="sms:/* phone number here */?body=/* body text here */">Link</a>


        <div class="button-space">
          <button *ngIf="!updatingData" [disabled]="!imeiIsValid || selectedType === ''"
                  class="item__action--primary pull-left" role="button"
                  (click)="submit()">Submit
          </button>
          <button *ngIf="updatingData" class="item__action--danger pull-left" role="button" (click)="ngOnDestroy()">
            Stop
          </button>
          <button class="item__action--secondary pull-left" role="button" (click)="clear()">Clear</button>
          <button class="item__action--alert pull-left" role="button"
                  (click)="clearAllInfo()">Clear all info
          </button>
        </div>
        <br/>
        <div *ngIf="updatingData" class="update-alert">
          <i class="fa fa-spinner fa-spin text-white"></i>
          <countdown #countdown [config]="{leftTime: 3, demand: true}"
                     (finished)="finishCountdown()">Updating data after: $!s!s.
          </countdown>
        </div>
      </div>
      <div *ngIf="result" class="result">
        <div class="abc-checkbox" [ngClass]="result.time ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Time: {{result.time}}</label>
        </div>
        <div class="abc-checkbox" [ngClass]="result.time ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Position: {{result.latitude}}, {{result.longitude}}</label>
        </div>
        <div *ngIf="hasCan()" class="abc-checkbox"
             [ngClass]="result.fuelLevel ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Fuel level: {{result.fuelLevel}}</label>
        </div>
        <div *ngIf="truck()" class="abc-checkbox"
             [ngClass]="result.axleWeight ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Axle weight: {{result.axleWeight}}</label>
        </div>
        <div *ngIf="truck()" class="abc-checkbox"
             [ngClass]="result.driver ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Driver: {{result.driver}}</label>
        </div>
        <div *ngIf="hasCan()" class="abc-checkbox"
             [ngClass]="result.distance ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Distance: {{result.distance}}</label>
        </div>

        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.freezerEngineOn ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Freezer Engine On: {{result.freezerEngineOn}}</label>
        </div>
        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.setFreezerTemperature ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Set Point Freezer Temperature: {{result.setFreezerTemperature}}</label>
        </div>
        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.actualFreezerTemperature ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Actual Freezer Temperature: {{result.actualFreezerTemperature}}</label>
        </div>
        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.supplyFreezerTemperature ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Supply Freezer Temperature: {{result.supplyFreezerTemperature}}</label>
        </div>
        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.secondCompartmentSetPoint ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Second Compartment Set Point: {{result.secondCompartmentSetPoint}}</label>
        </div>
        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.secondCompartmentSupplyTemperature ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Second Compartment Supply Temperature: {{result.secondCompartmentSupplyTemperature}}</label>
        </div>
        <div *ngIf="ref()" class="abc-checkbox"
             [ngClass]="result.secondCompartmentTemperature ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Second Compartment Temperature: {{result.secondCompartmentTemperature}}</label>
        </div>


        <div *ngIf="truck()" class="abc-checkbox"
             [ngClass]="correctTacho() ? 'abc-checkbox-success' : 'abc-checkbox-danger'">
          <input checked="" disabled="" type="checkbox">
          <label>Tacho check: {{result.tachoCheck}}</label> <span *ngIf="tachoSent" style="color: red">Loading... Press tacho status button to force response</span>
          <br/>
          <button *ngIf="!tachoSent" class="item__action--primary" (click)="sendTachoCheck()">Send tacho check</button>
        </div>
      </div>
    </div>
  </div>

</div>
