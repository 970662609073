export class AuthConfig {
  public static readonly CLIENT: string = btoa('fleet-management:7Hpp9WRzDMnehrS36LMk');
  public static readonly CLIENT_ID: string = 'fleet-management';
  public static readonly LOGIN_ROUTE = '/auth/login';
  public static readonly LOGIN_VERIFICATION = '/auth/verification'
  public static readonly DEFAULT_ROUTE = '';
  public static readonly CLIENT_MAIN_PAGE = '/monitor';
  public static readonly PARTNER_DEFAULT_PAGE = '/monitor';
  public static readonly CHANGE_PASSWORD_ROUTE = '/profile/change-password'
}
