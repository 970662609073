import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SwitchClientEntry} from '../model/switch-client.entry';
import {HttpClient} from '@angular/common/http';
import {ControlPanelSettingsEntry} from '../model/control-panel-settings.entry';
import {TokenResponseEntry} from '../model/token-response.entry';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  public update(data: any): Observable<any> {
    return this.http.put('/api/profile', data);
  }

  public changePassword(passwords: any, locale: string): Observable<void> {
    return this.http.put<void>(`/api/profile/change-password?locale=${locale}`, passwords);
  }

  public clients(): Observable<SwitchClientEntry> {
    return this.http.get<SwitchClientEntry>('/api/profile/available-clients');
  }

  public switchProfile(id: number): Observable<TokenResponseEntry> {
    return this.http.get<TokenResponseEntry>('/api/profile/switch/' + id);
  }

  public getControlPanelSettings(): Observable<ControlPanelSettingsEntry> {
    return this.http.get<ControlPanelSettingsEntry>('/api/profile/control-panel-settings');
  }

  public updateControlPanelSettings(data: ControlPanelSettingsEntry): Observable<ControlPanelSettingsEntry> {
    return this.http.put<ControlPanelSettingsEntry>('/api/profile/control-panel-settings', data);
  }

  public getTaskManagingSettings(): any {
    return this.http.get('/api/profile/task-managing-settings');
  }

  public updateTaskManagingSettings(data: any) {
    return this.http.put('/api/profile/task-managing-settings', data);
  }

  public getSecuritySettings(): any {
    return this.http.get('/api/profile/security-settings');
  }

  public updateSecuritySettings(data: any) {
    return this.http.put('/api/profile/security-settings', data);
  }

  public showThemesSelection(): Observable<boolean> {
    return this.http.get<boolean>('/api/profile/show-themes-selection');
  }
}
