import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthConfig} from '../../core/auth/auth-config';
import {AuthStorage} from '../../core/auth/auth-storage';
import {GlobalService} from '../../core/global/global.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, share, takeUntil} from 'rxjs/operators';
import {Userpilot} from 'userpilot';
import {CookieService} from 'ngx-cookie';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {SubscriptionUtils} from '../../core/commons/subscription.utils';
import {FM_LANG} from '../../core/global/cookies.constants';
import {DeviceService} from '../../core/commons/device.service';
import {environment} from '../../../environments/environment';
import {GlobalConstants} from '../../core/global/global.constants';
import {isPartner, Profile} from '../../core/model/profile';
import {OidcSecurityService} from 'angular-auth-oidc-client';

const TOO_MANY_FAILED_LOGINS_ERROR = 'Too many failed logins';
const VERIFICATION_NEEDED_ERROR = 'Verification needed';
const LOGIN_TRIES_ERROR = 'Login tries left';

const isWebview = require("is-ua-webview");

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnDestroy {

  private readonly oidcSecurityService = inject(OidcSecurityService);

  public account = {username: '', password: ''};
  public failed = false;
  public tooManyFailedLogins = false;
  public triesLeft: number;
  public supportContact: string;
  public shouldShowLogo = environment.name !== 'PROD'
    || GlobalConstants.FHDomains.includes(location.hostname.split('.')[0]);

  private subSubject = new Subject<boolean>();

  constructor(private http: HttpClient,
              private router: Router,
              private globalService: GlobalService,
              private reCaptchaService: ReCaptchaV3Service,
              private cookieService: CookieService,
              private deviceService: DeviceService) {
  }

  public login() {
    let appearance = AuthStorage.getAppearance();
    localStorage.clear();
    AuthStorage.setAppearance(appearance);
    this.handleSilentLoginFields()
    this.reCaptchaService.execute('login')
      .pipe(takeUntil(this.subSubject))
      .subscribe({
        next: (token) => {
          this.doLogin(this.account.username, this.account.password, token)
            .pipe(takeUntil(this.subSubject))
            .subscribe({
              next: (data) => {
                AuthStorage.persistTokens(data['access_token'], data['refresh_token']);
                AuthStorage.persistPasswordExpiration(data['password-expired'])
                let redirectTo = AuthStorage.stateUrl() || AuthConfig.DEFAULT_ROUTE;
                AuthStorage.clearStateUrl();
                this.globalService.getLoginDetails()
                  .pipe(takeUntil(this.subSubject))
                  .subscribe({
                    next: (loggedUser) => {
                      AuthStorage.updateUserSettings(loggedUser, true, this.cookieService);
                      this.deviceService.isMobile()
                        ? Userpilot.destroy()
                        : this.initializeUserPilot(loggedUser);
                      this.router.navigate([redirectTo]);
                    },
                    error: () => this.failed = true
                  });
              },
              error: (err) => this.parseError(err)
            });
        },
        error: (err) => {
        }
      });
  }

  private initializeUserPilot(user: Profile): void {
    let lang = this.cookieService.get(FM_LANG);
    let companyId = user.company.id;
    Userpilot.identify(user.id.toString(), {
      name: user.fullName,
      email: user.email,
      locale_code: lang ? lang : 'en',
      company: {
        id: isPartner(user.role.toString()) ? 'P' + companyId : companyId,
        name: user.company.value
      }
    });
  }

  private parseError(error) {
    const errorDescription = error.error != null ? error.error : '';
    if (errorDescription.includes(VERIFICATION_NEEDED_ERROR)) {
      this.router.navigate(
        [AuthConfig.LOGIN_VERIFICATION],
        {queryParams: {email: this.account.username}, skipLocationChange: true}
      );
    } else if (errorDescription.startsWith(TOO_MANY_FAILED_LOGINS_ERROR)) {
      this.tooManyFailedLogins = true;
      this.supportContact = this.parseSupportContact(errorDescription);
    } else {
      this.triesLeft = this.parseFailedTries(errorDescription);
      this.tooManyFailedLogins = this.triesLeft === 0;
      this.failed = true;
    }
  }

  private parseFailedTries(errorDescription: string): number {
    if (errorDescription.includes(LOGIN_TRIES_ERROR)) {
      return Number(errorDescription.split('=')[1]);
    } else {
      return null;
    }
  }

  private parseSupportContact(errorDescription: string): string {
    return errorDescription.split('\n')[1];
  }

  private doLogin(username: string, password: string, token: string): Observable<string> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Basic ' + AuthConfig.CLIENT)
      .set('Accept', 'application/json')
      .set('reCaptcha-token', token);

    let request = 'grant_type=password&username=' + username
      + '&password=' + password
      + '&logging-in='
      + '&encodedPassword=' + btoa(password);
    return this.http.post<string>('/oauth/token', request, {headers: headers})
      .pipe(
        share(), map(res => {
          return res
        })
      );
  }

  private handleSilentLoginFields() {
    if (isWebview(navigator.userAgent) && this.account.username === '' && this.account.password === '') {
      const usernameInput: any = document.getElementsByName('username')[0];
      const passwordInput: any = document.getElementsByName('password')[0];
      this.account = {
        username: usernameInput != null
          ? usernameInput.value
          : '',
        password: passwordInput != null
          ? passwordInput.value
          : ''
      };
    }
  }

  public ngOnDestroy(): void {
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }

  public loginV1() {
    this.reCaptchaService.execute('login')
      .pipe(takeUntil(this.subSubject))
      .subscribe({
        next: (token) => {
          let headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Accept', 'application/json');

          let request = 'username=' + this.account.username
            + '&password=' + encodeURIComponent(this.account.password)
            + '&action=logging-in'
            + '&reCaptcha-token=' + token
            + '&encodedPassword=' + btoa(this.account.password);
          this.http.post<void>(`/oauth/login`, request, {withCredentials: true, headers: headers})
            .pipe(takeUntil(this.subSubject))
            .subscribe({
                next: () => this.oidcSecurityService.authorize(),
                error: (err) => this.parseError(err)
              }
            );
        },
        error: (err) => {
        }
      })
  }

  public loginWithAzureAd() {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', 'application/json')
    this.http.get<any>(`/login/oauth2`, {withCredentials: true, headers: headers})
      .subscribe(
        (next) => {
          let popupLogin = window.open(next.redirectUrl, '_blank', 'width=500,height=700');
          this.handlePopupLogin(popupLogin)
        }
      )
  }

  private handlePopupLogin(popupLogin: Window) {
    const interval = setInterval(() => {
      try {
        const url = popupLogin.location.href;
        if (url.includes(environment.issuerUrl)) {
          let accessToken = new URLSearchParams(popupLogin.location.search).get("accessToken");
          let refreshToken = new URLSearchParams(popupLogin.location.search).get("refreshToken");
          popupLogin.close();
          clearInterval(interval);
          this.finishLogin(accessToken, refreshToken);
        }
      } catch (error) {
      }
    }, 500);
  }

  private finishLogin(accessToken: string, refreshToken: string) {
    if (accessToken != null && accessToken !== '' && refreshToken != null && accessToken !== '') {
      AuthStorage.persistTokens(accessToken, refreshToken)
      let redirectTo = AuthStorage.stateUrl() || AuthConfig.DEFAULT_ROUTE;
      AuthStorage.clearStateUrl();
      this.globalService.getLoginDetails()
        .pipe(takeUntil(this.subSubject))
        .subscribe({
          next: (loggedUser) => {
            AuthStorage.updateUserSettings(loggedUser, true, this.cookieService);
            this.deviceService.isMobile()
              ? Userpilot.destroy()
              : this.initializeUserPilot(loggedUser);
            this.router.navigate([redirectTo]);
          },
          error: () => this.failed = true
        });
    } else {
      this.failed = true
    }
  }

}
