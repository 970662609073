import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbPopover,
  NgbPopoverConfig,
  NgbTimepickerConfig,
  NgbTimeStruct
} from '@ng-bootstrap/ng-bootstrap';
import {DateFormatUtils} from './date-format.utils';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {dateValidation, dateValidationWithTime, timeValidation} from '../../validators';
import {DateI18nUtils, I18n} from './date-i18n.utils';
import {GlobalConstants} from '../../global/global.constants';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: DateI18nUtils}, NgbPopoverConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DateTimePickerComponent implements OnInit {

  current = new Date();

  @Input() public pickerType: string;
  @Input() public form: UntypedFormGroup;
  @Input() public controlName: string;
  @Input() public requiresSmallInput = false;
  @Input() public placement;
  @Input() public placeHolder: string;
  @Input() public autoSetTime = true;
  @Input() public validationEnabled = true;
  @Input() public setDefaultTime = true;
  @Input() public isOrderForm = false;
  @Input() public isDateValid = false;
  @Input() public iconCenter = false;
  @Input() public narrow = false;
  @Input() public disabled = false;
  @Input() public minDate: NgbDateStruct;
  @Input() public maxDate: NgbDateStruct;
  @Input() public autocomplete = 'on';

  @Output()
  public datePicked = new EventEmitter<any>();

  @Output()
  public timePicked = new EventEmitter<any>();

  @Output()
  public dateValueChanged = new EventEmitter<any>();

  @Output()
  public closed = new EventEmitter<string>();

  public date: NgbDateStruct;

  public time: NgbTimeStruct;

  public timePickerTouched = false;

  @ViewChild(NgbPopover)
  public popover: NgbPopover;

  public constructor(private timeConfig: NgbTimepickerConfig,
                     private dateFormatUtils: DateFormatUtils,
                     private dateConfig: NgbDatepickerConfig,
                     private popOverConfig: NgbPopoverConfig) {

    timeConfig.seconds = false;
    timeConfig.spinners = true;
    timeConfig.minuteStep = 5;
  }

  private setDefaultValues() {
    this.date = {
      year: this.current.getUTCFullYear(),
      month: this.current.getUTCMonth() + 1,
      day: this.current.getUTCDate()
    };

    if (this.setDefaultTime) {
      this.time = {
        hour: this.current.getHours(),
        minute: this.current.getUTCMinutes(),
        second: 0
      };
    }
  }

  public ngOnInit(): void {
    this.setDefaultValues();

    this.popOverConfig.autoClose = 'outside';

    if (window.innerWidth < GlobalConstants.MOBILE_FOOTER_CHANGE_BREAKPOINT) {
      this.popOverConfig.placement = 'left';
    } else {
      this.popOverConfig.placement = this.placement;
    }

    if (this.form) {
      this.createFilters();
      if (this.getControls().value) {
        this.changeStringToDateModel();
      }
    }

    if (this.disabled) {
      this.form.get(this.controlName).disable();
    }
  }

  public changeDateToString(): void {
    this.getControls().patchValue(this.getFullDateString());
    this.valueChanged();
  }

  public changeStringToDateModel(): void {
    if (this.validationEnabled && this.getControls().value) {
      this.date = this.dateToModel(this.fullDateStringToDateString(this.getControls().value));
      this.time = this.timeToModel(this.fullDateStringToTimeString(this.getControls().value));
      this.changeDateToString();
    } else {
      this.valueChanged();
    }
  }

  public openCorrectMonth(): void {
    if (this.pickerType !== 'TIME') {
      this.dateConfig.startDate = {
        year: this.date.year,
        month: this.date.month
      };
      if (this.autoSetTime === false) {
        this.form.get(this.controlName).setValue('');
        this.timePickerTouched = false;
      }
    }
  }

  public getPlaceHolder(): string {
    if (this.placeHolder) {
      return this.placeHolder
    }
    switch (this.pickerType) {
      case ('DATE_TIME'):
        return 'YYYY-MM-DD HH:MM (24h)';
      case ('TIME'):
        return 'HH:MM (24h)';
      case ('DATE'):
        return 'YYYY-MM-DD';
    }
  }

  private getFullDateString(): string {
    switch (this.pickerType) {
      case ('DATE_TIME'):
            if (this.autoSetTime === false && this.timePickerTouched === false) {
              return this.dateToString(this.date);
            } else {
              return this.dateToString(this.date) + ' ' + this.timeToString(this.time);
            }
      case ('TIME'):
        return this.timeToString(this.time);
      case ('DATE'):
        return this.dateToString(this.date);
    }
  }

  private timeToString(time: NgbTimeStruct): string {
    this.timePicked.emit();
    return this.dateFormatUtils.timeToString(time, '');
  }

  private dateToString(date: NgbDateStruct): string {
    return this.dateFormatUtils.format(date);
  }

  private dateToModel(dateString: string): NgbDateStruct {
    return this.dateFormatUtils.parse(dateString);
  }

  private timeToModel(timeString: string): NgbTimeStruct {
    return this.dateFormatUtils.timeToModel(timeString);
  }

  private fullDateStringToDateString(fullDate: string): string {
    switch (this.pickerType) {
      case ('DATE_TIME'):
        let date = fullDate.substring(0, fullDate.indexOf(' '));
        DateFormatUtils.adjustDate(date);
        return date;
      case ('DATE'):
        DateFormatUtils.adjustDate(fullDate);
        return fullDate;
      default :
        return null;
    }
  }

  private fullDateStringToTimeString(fullDate: string): string {
    switch (this.pickerType) {
      case ('DATE_TIME'):
        return fullDate.substring(fullDate.indexOf(' ') + 1, fullDate.length);
      case ('TIME'):
        return fullDate;
      default :
        return null;
    }
  }

  private createFilters(): void {
    if (this.validationEnabled) {
      switch (this.pickerType) {
        case ('DATE_TIME'):
          this.setupValidators(dateValidationWithTime);
          break;
        case ('DATE'):
          this.setupValidators(dateValidation);
          break;
        case ('TIME'):
          this.setupValidators(timeValidation);
          break;
      }
    }
  }

  private setupValidators(validator: any) {
    let presetValidators = this.getControls().validator;
      this.getControls().setValidators(Validators.compose([
        presetValidators,
        validator]));
  }

  private getControls(): UntypedFormControl {
    return this.form.get(this.controlName) as UntypedFormControl;
  }

  public dateSelected() {
    this.changeDateToString();
    this.datePicked.emit();
  }

  public timePickerClicked() {
    if (this.autoSetTime === false) {
      this.timePickerTouched = true
    }
  }

  public onInputEntered(): void {
    this.closed.emit(this.getControls().value);
  }

  public closeWithEmit(): void {
    this.closed.emit(this.getControls().value);
    this.popover.close();
  }

  private valueChanged() {
    this.dateValueChanged.emit(this.getControls().value);
  }
}
