import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {EMPTY, Observable, tap, throwError} from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { JwtInterceptor } from '@auth0/angular-jwt';
import {AuthService} from './auth.service';
import {AuthStorage} from '../core/auth/auth-storage';
import {DeviceService} from '../core/commons/device.service';
import {Router} from '@angular/router';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private static MONITOR_PATHS: string[] = ['/', '/monitor']

  private unauthorizedIntercepted = false;

  public constructor (private authService: AuthService,
                      private jwtInterceptor: JwtInterceptor,
                      private deviceService: DeviceService,
                      private router: Router) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any | unknown>> {
    if (this.jwtInterceptor.isAllowedDomain(req) && !this.jwtInterceptor.isDisallowedRoute(req)) {
      return next.handle(req).pipe(
        catchError((err) => {
          const errorResponse = err as HttpErrorResponse;
          if (errorResponse.status === 401) {
            if (!AuthStorage.isAccessTokenValid()
              && AuthStorage.isRefreshTokenValid()
              && !this.unauthorizedIntercepted) {

              this.unauthorizedIntercepted = true;
              return this.authService.updateTokens().pipe(mergeMap(() => {
                if (this.deviceService.isMobile() && RefreshTokenInterceptor.MONITOR_PATHS.includes(this.router.url)) {
                  location.reload();
                }
                return this.jwtInterceptor.intercept(req, next);
              }));
            }
            return EMPTY;
          } else if (errorResponse.status === 440) {
            this.authService.logOut();
            return EMPTY;
          }
          return throwError(err);
        }),
        tap(() => this.unauthorizedIntercepted = false));
    } else {
      return next.handle(req);
    }
  }
}
