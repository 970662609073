<main class="auth__container">
  <form (ngSubmit)="loginV1()" class="auth" #form="ngForm" role="login">
    <div class="auth__branding">
      <img *ngIf="shouldShowLogo" src="/assets/img/logo/fleethand-logo--ua.svg" alt="Fleethand" class="auth__logo" />
    </div>
    <div class="auth__form">
      <input appTrimInputValue
             type="text"
             [(ngModel)]="account.username"
             name="username"
             required="required"
             class="form-control input-lg"
             placeholder="{{'login.username' | translate}}"/>

      <input type="password"
             [(ngModel)]="account.password"
             name="password"
             required="required"
             class="form-control input-lg"
             placeholder="{{'login.password' | translate}}"/>

      <button [disabled]="!form.valid" class="auth__btn" type="submit" role="button">
        {{'login.login' | translate}}
      </button>

      <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="failed && !tooManyFailedLogins">
        {{'login.failed' | translate}}
        <ng-container *ngIf="triesLeft != null && triesLeft <= 5">
          {{'login.triesLeft' | translate}}{{triesLeft}}
        </ng-container>
      </ngb-alert>

      <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="tooManyFailedLogins">
        {{'login.tooManyFailedLogins' | translate}}
        <br>
        {{this.supportContact}}
      </ngb-alert>

      <p class="auth__links">
        <a routerLink="/auth/remind">{{'login.forgot' | translate}}</a>
      </p>
      <div class="auth__azure">
        <a (click)="loginWithAzureAd()" class="auth__btn--azure" type="submit" role="button">
          <i class="ico-microsoft sz-m mr-1"></i> Authorize with Microsoft
        </a>
      </div>
    </div>
  </form>
</main>

