import {Injectable} from '@angular/core';
import {Pageable} from '../../../core/page/pageable';
import {DriverFilter} from './drivers-list/driver.filter';
import {PageEntry} from '../../../core/page/page.entry';
import {Observable, of} from 'rxjs';
import {DriverEntry} from './driver.entry';
import {DriverForm} from './driver.form';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpUtils} from '../../../core/page/commons/http.utils';
import {map} from 'rxjs/operators';
import {DriverNotesEntry} from './driver-info/model/driver-notes.entry';
import {DriverDocumentEntry} from './driver-info/driver-documents/model/driver-document.entry';
import {DriverDocumentForm} from './driver-info/driver-documents/model/driver-document.form';

@Injectable()
export class DriversService {

  constructor(private http: HttpClient) {
  }

  public fetchDrivers(filter: DriverFilter, pageable: Pageable): Observable<PageEntry<DriverEntry>> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'fullName', filter.fullName);
    params = HttpUtils.appendParam(params, 'driverCard', filter.driverCard);
    params = HttpUtils.appendParam(params, 'phoneNumber', filter.phoneNumber);
    params = HttpUtils.appendParam(params, 'department', filter.department);
    params = HttpUtils.appendParam(params, 'tableNumber', filter.tableNumber);
    params = HttpUtils.appendParam(params, 'status', filter.status);
    params = HttpUtils.appendParam(params, 'clientTitle', filter.clientTitle);
    params = HttpUtils.appendParam(params, 'page', pageable.pageNumber);
    params = HttpUtils.appendParam(params, 'size', pageable.pageSize);

    return this.http.get<PageEntry<DriverEntry>>('/api/drivers', {params: params});
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>('/api/drivers/' + id, {});
  }

  public create(form: any): Observable<number> {
    return this.http.post<number>('/api/drivers', form);
  }

  public getDriver(id: number): Observable<DriverForm> {
    return this.http.get<DriverForm>('/api/drivers/' + id);
  }

  public update(id: number, form: any): Observable<void> {
    return this.http.put<void>('/api/drivers/' + id, form);
  }

  public validateDriverCard(fieldName: string, driverId: number) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.isDriverCardPresent(control.value, driverId)
        .pipe(map(valid => valid ? {'driverForm.notUniqueDriverCard': true} : null));
    };
  }

  public isDriverCardPresent(driverCard: string, driverId: number): Observable<boolean> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'driverCard', driverCard);
    params = HttpUtils.appendParam(params, 'driverId', `${driverId}`);
    return this.http.get<boolean>('/api/drivers/check-driver-card', {params: params});
  }

  public canFillForm(driverCard: string): Observable<number> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'driverCard', driverCard);
    return this.http.get<number>('/api/drivers/can-fill-form', {params: params});
  }

  public validateTableNumber(fieldName: string, driverId: number): AsyncValidatorFn | boolean {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        if (control.value != null && control.value.length > 0) {
          return this.isTableNumberPresent(control.value, driverId)
            .pipe(map(valid => {
              return valid ? {'driverForm.notUniqueTableNumber': true} : null;
            }));
        } else {
          return of(null);
        }
    };
  }

  public isTableNumberPresent(tableNumber: string, driverId: number): Observable<boolean> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'tableNumber', tableNumber);
    params = HttpUtils.appendParam(params, 'driverId', `${driverId}`);
    return this.http.get<boolean>('/api/drivers/check-table-number', {params: params});
  }

  public createNote(form: any): Observable<void> {
    return this.http.post<void>('/api/driver-notes', form);
  }

  public fetchDriverNotes(driverId: number): Observable<DriverNotesEntry[]> {
    return this.http.get<DriverNotesEntry[]>(`/api/driver-notes?driverId=${driverId}`);
  }

  public deleteNote(id: number): Observable<void> {
    return this.http.delete<void>(`/api/driver-notes?id=${id}`, {});
  }

  public updateNote(text: string, reminder: string, id: number): Observable<void> {
    return this.http.put<void>(`/api/driver-notes`, {id, reminder, text});
  }

  public downloadExcel(filter: DriverFilter, currentLang: string) {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'fullName', filter.fullName);
    params = HttpUtils.appendParam(params, 'driverCard', filter.driverCard);
    params = HttpUtils.appendParam(params, 'phoneNumber', filter.phoneNumber);
    params = HttpUtils.appendParam(params, 'department', filter.department);
    params = HttpUtils.appendParam(params, 'tableNumber', filter.tableNumber);
    params = HttpUtils.appendParam(params, 'status', filter.status);
    params = HttpUtils.appendParam(params, 'locale', currentLang);
    return this.http.get('/api/drivers/excel', {
      responseType: 'blob',
      params: params
    })
      .pipe(map(res => <Blob>res));
  }

  /*-------------------------------------------- Documents --------------------------------------------------------*/

  public saveDocument(driverId: number, file: File, form: DriverDocumentForm): Observable<void> {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('form', new Blob([JSON.stringify(form)], {type: 'application/json'}));
    return this.http.post<void>(`/api/driver-documents?driverId=${driverId}`, formData);
  }

  public deleteDocument(fileId: number): Observable<void> {
    return this.http.delete<void>(`/api/driver-documents/${fileId}`);
  }

  public fetchDriverDocuments(driverId: number): Observable<DriverDocumentEntry[]> {
    return this.http.get<DriverDocumentEntry[]>(`/api/driver-documents?driverId=${driverId}`);
  }

  public downloadDriverDocuments(fileId: number): Observable<Blob> {
    return this.http.post(`/api/driver-documents/${fileId}/download`, {}, {responseType: 'blob'})
      .pipe(map(res => <Blob>res));
  }

  public switchShowToDriver(fileId: number, showToDriver: boolean): Observable<void> {
    return this.http.put<void>(
      `/api/driver-documents/${fileId}/switch-show-to-driver?showToDriver=${showToDriver}`, {});
  }

  public fetchDocumentUri(): Observable<string> {
    return this.http.get(`/api/driver-documents/file-uri`, {responseType: 'text'});
  }
}
